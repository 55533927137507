import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import { Canvas, useFrame } from 'react-three-fiber';
import { useSpring, a } from 'react-spring/three';

const StyledDiv = styled.div`
  height: 100vh;
  transition: .3s ease;
`;

const Box = () => {
    const meshRef = useRef();
    const [hovered, setHovered] = useState(false);
    const [active, setActive] = useState(false);
    const props = useSpring({
        scale: active ? [1.5, 1.5, 1.5] : [1, 1, 1],
        color: hovered ? 'gray' : 'blue'
    });

    useFrame(() => {
        meshRef.current.rotation.y += 0.01
    });

    return (
        <a.mesh
            ref={meshRef}
            onPointerOver={() => setHovered(true)}
            onPointerOut={() => setHovered(false)}
            onClick={() => setActive(!active)}
            scale={props.scale}
        >
            <boxBufferGeometry attach="geometry" arcs={[1,1,1]} />
            <a.meshBasicMaterial
                attach="material"
                color={props.color}
            />
        </a.mesh>
    )
};

export default () => {
    return (
        <StyledDiv>
            <Canvas  >
                <Box />
            </Canvas>
        </StyledDiv>
    )
};